<template>
  <div class="calculator-page delivery-calculator-page main-wrapper">
    <SSPHeader/>
    <main class="container">
      <section class="content">
        <div class="search_code">
          <SSPLoader v-if="isLoading" />
          <div>
            <h1>Установка приоритета транспортных компаний калькулятора доставки</h1>
            <div class="fieldset-group-inline" v-if="!isLoading">
              <div>
                <span><b>Деловые линии</b></span>
                <SSPSelector :placeholder="'Без приоритета'" :value="sendData.status.dellin" @setValue="sendData.status.dellin = $event" :options="dailyProps" />
                <div class="days_list" v-if="sendData.status.dellin === 3">
                  <div v-for="(day, index) in days"
                       :key="index"><span>{{ day }}:</span> <SSPSelector :placeholder="'Без приоритета'" :value="sendData.daily.dellin[index]" @setValue="sendData.daily.dellin[index] = $event" :options="priorities" />
                  </div>
                </div>
              </div>
              <div>
                <span><b>КИТ</b></span>
                <SSPSelector :placeholder="'Без приоритета'" :value="sendData.status.kit" @setValue="sendData.status.kit = $event" :options="dailyProps" />
                <div class="days_list" v-if="sendData.status.kit === 3">
                  <div v-for="(day, index) in days"
                       :key="index"><span>{{ day }}:</span> <SSPSelector :placeholder="'Без приоритета'" :value="sendData.daily.kit[index]" @setValue="sendData.daily.kit[index] = $event" :options="priorities" />
                  </div>
                </div>
              </div>
              <div>
                <span><b>DPD</b></span>
                <SSPSelector :placeholder="'Без приоритета'" :value="sendData.status.dpd" @setValue="sendData.status.dpd = $event" :options="dailyProps" />
                <div class="days_list" v-if="sendData.status.dpd === 3">
                  <div v-for="(day, index) in days"
                       :key="index"><span>{{ day }}:</span> <SSPSelector :placeholder="'Без приоритета'" :value="sendData.daily.dpd[index]" @setValue="sendData.daily.dpd[index] = $event" :options="priorities" />
                  </div>
                </div>
              </div>
            </div><!--fieldset-->
            <!--fieldset-->
            <div class="actions">
              <div>
                <button class="btn btn-submit" @click="setPriority">Установить</button>
              </div>
              <div v-if="success === 'ok'" class="result">
                <span>Приортитеты ТК успешно установлены</span>
              </div>
            </div><!--actions-->
          </div>
        </div>
      </section>
    </main>
    <SSPFooter/>
  </div>
</template>

<script>
import SSPHeader from "@/components/Header";
import SSPFooter from "@/components/Footer";
import SSPLoader from "@/components/Loader";
import SSPSelector from "@/components/Selector";
import {mapState} from "vuex";
import {actionTypes as deliveryAction} from "@/store/modules/delivery";

export default {
  name: 'SSPDeliveryCalculatorPriority',
  components: {
    SSPHeader,
    SSPFooter,
    SSPLoader,
    SSPSelector,
  },
  data(){
    return {
      companies: {
        kit: 'ТК Кит',
        dellin: 'Деловые линии',
        dpd: 'DPD'
      },
      days: {
        1: "Понедельник",
        2: "Вторник",
        3: "Среда",
        4: "Четверг",
        5: "Пятница",
        6: "Суббота",
        7: "Воскресенье"
      },
      sendData: {
        status: {
          kit: 0,
          dellin: 0,
          dpd: 0
        },
        daily: {
          kit: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0
          },
          dellin: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0
          },
          dpd: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0
          }
        }
      },
      priorities: [
        {label: 'Без приоритета', value: 0},
        {label: 'Средний приоритет', value: 1},
        {label: 'Высокий приоритет', value: 2}
      ],
      dailyProps: [
        {label: 'Без приоритета', value: 0},
        {label: 'Средний приоритет', value: 1},
        {label: 'Высокий приоритет', value: 2},
        {label: 'Приоритет по дням недели', value: 3}
      ]
    }
  },
  mounted() {
    this.getPriority()
  },
  computed: {
    ...mapState({
      error: state => state.dadata.error,
      success: state => state.delivery.success,
      result: state => state.delivery.result,
      isLoading: state => state.delivery.isLoading,
      isLoggedIn: state => state.auth.isLoggedIn
    })
  },
  watch: {
    sendData: {
      async handler () {
        if (this.success === 'ok') {
          await this.$store.dispatch(deliveryAction.resetSuccessStatus)
        }
      },
      deep: true
    }
  },
  methods: {
    async getPriority () {
      this.sendData = Object.assign({}, await this.$store.dispatch(deliveryAction.getPriority))
    },
    async setPriority () {
      if (this.sendData.to !== '') await this.$store.dispatch(deliveryAction.setPriority, { ...this.sendData })
    }
  }
}
</script>
